<template>
  <Scaffold>
    <template #buttons>
      <Button type="primary" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="lectureList"
          v-bind="tableBinding()"
          @reload="getData"
      >
        <template #action="{row}">
          <Button size="small"
                  @click="() => $router.push({name: 'lecture-step-list', params: {lectureId: row.id}})"
          >회차 목록
          </Button>
          <Button size="small" type="warning"
                  @click="() => onEdit(row.id)">수정
          </Button>
        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  name: 'LectureList',
  mixins: [tableMixin],
  data() {
    return {
      apiUrl: 'lecture',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    onCreate() {
      this.$axios.$post(this.apiUrl).then(res => {
        this.$router.push({name: 'lecture-editor', params: {id: res.result.id}})
      })
    },
    onEdit(id) {
      this.$router.push({name: 'lecture-editor', params: {id}})
    },
    onDelete(id) {

    }
  }
}
</script>

<style lang="scss" scoped>
</style>